<template>
  <ConfirmDialog></ConfirmDialog>
  <div class="align-items-center card flex grid">
    <div class="grid col-12">
      <div class="col-2 text-right">
        <h3 class="mb-0" for="title">CAJA#{{ caja.id }}</h3>
      </div>
      <div class="col-2 text-right">
        <span
          :class="
            'border-round-2xl customer-badge p-2 ' + claseEstado[caja.estado]
          "
        >
          <i
            style="font-size: 1.3rem"
            :class="
              this.caja.estado == 1
                ? 'font-semibold pi pi-lock-open'
                : 'font-semibold pi pi-lock'
            "
          ></i>
          {{ this.caja.estado_texto }}
        </span>
      </div>
    </div>
    <div class="grid col-12">
      <div class="col-6">
        <!-- <div class="grid">
          <label class="col-4 text-right" for="id"
            ><strong>#Caja : </strong></label
          >
          <span class="col-4 text-right">{{ caja.id }}</span>
        </div> -->
        <div class="grid">
          <label class="col-4 text-right" for="id"
            ><strong>Usuario : </strong></label
          >
          <span class="col-4 text-right">{{ caja.nombre_user }}</span>
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="id"
            ><strong>Sucursal : </strong></label
          >
          <span class="col-4 text-right">{{ caja.nombre_sucursal }}</span>
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="fecha"
            ><strong>Fecha Apertura : </strong></label
          >
          <span class="col-4 text-right">{{ caja.fecha_apertura }}</span>
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="saldo_inicial"
            ><strong>Fecha Cierre : </strong></label
          >
          <span class="col-4 text-right">{{
            caja.fecha_cierra ?? "No definido"
          }}</span>
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="saldo_inicial"
            ><strong>Saldo Inicial : </strong></label
          >
          <span class="col-4 text-right">{{ caja.saldo_inicial }} Bs</span>
        </div>
      </div>
      <div class="col-6">
        <div class="grid">
          <label class="col-4 text-right" for="id"
            ><strong>Total Ventas : </strong></label
          >
          <span class="col-4 text-right"
            >{{ caja.total_ventas ?? "0,00" }} Bs</span
          >
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="id"
            ><strong>Recuento Efectivo : </strong></label
          >
          <span class="col-4 text-right"
            >{{ caja.recuento_efectivo ?? "0,00" }} Bs</span
          >
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="id"
            ><strong>Diferencia : </strong></label
          >
          <span class="col-4 text-right"
            >{{ caja.diferencia ?? "0,00" }} Bs</span
          >
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="fecha"
            ><strong>Monto a Entregar : </strong></label
          >
          <span class="col-4 text-right"
            >{{ caja.monto_entregar ?? "0,00" }} Bs</span
          >
        </div>
        <div class="grid">
          <label class="col-4 text-right" for="saldo_inicial"
            ><strong>Saldo Caja : </strong></label
          >
          <span class="col-4 text-right"
            >{{ caja.saldo_caja ?? "0,00" }} Bs</span
          >
        </div>
      </div>
    </div>
    <div class="grid col-6">
      <label for="observacion"
        ><strong>Observacion : </strong>{{ caja.observacion }}</label
      >
    </div>
  </div>
  <div class="grid">
    <div class="card col-12">
      <h5><strong>Movimientos de Caja</strong></h5>
      <DataTable
        ref="dtmovcajas"
        :value="caja.mov_cajas"
        dataKey="id"
        class="p-datatable-sm"
      >
      <Column field="id" header="ID"></Column>
      <Column field="caja_id" header="ID CAJA"></Column>
      <Column field="fecha_mov" header="FECHA MOVIMIENTO"></Column>
      <Column field="tipo_mov_texto" header="TIPO MOVIMIENTO"></Column>
      <Column field="importe" header="IMPORTE"></Column>
      <Column field="concepto" header="CONCEPTO"></Column>
      <Column field="autorizado_por" header="AUTORIZADO POR"></Column>

      </DataTable>
    </div>
  </div>
  <div class="grid">
    <div class="card col-12">
      <h5><strong>Movimientos de Ventas</strong></h5>
      <DataTable
        ref="detalle"
        :value="caja.ventas"
        dataKey="id"
        class="p-datatable-sm"
      >
        <Column class="text-right" field="id" header="N° VENTA"></Column>
        <Column field="nombre_cliente" header="CLIENTE"></Column>
        <Column field="fecha_venta" header="FECHA VENTA"></Column>
        <Column field="nit" header="NIT/CI"></Column>
        <Column class="text-right" field="total_cancelar" header="TOTAL VENTA">
          <template #body="slotProps">
            {{
              convertirNumeroGermanicFormat(slotProps.data.total_cancelar) +
              " Bs"
            }}
          </template>
        </Column>
        <Column field="estado" header="ESTADO">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado2[data.estado]">
              {{ data.estado_texto }}
            </span>
          </template>
        </Column>
        <!-- <Column class="text-right" header="ACCIONES">
          <template #body="slotProps">
            <Button
              label="Ver Venta"
              class="p-button-info"
              icon="pi pi-eye"
              @click="detalleVenta(slotProps.data.id)"
            />
          </template>
        </Column> -->
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              icon="pi pi-ellipsis-v"
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            ></Button>
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            ></Menu>
          </template>
        </Column>
        <template #footer>
          <div class="text-right font-bold">
            TOTAL VENTAS :
            {{
              convertirNumeroGermanicFormat(caja.calculo_total_ventas) + " Bs"
            }}
          </div>
        </template>
      </DataTable>
      <div class="grid mt-2 justify-content-end">
        <Button @click="goBack">
          <i class="pi pi-arrow-circle-left mr-1"></i>
          &nbsp;Atr&aacute;s
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import CajaService from "@/service/CajaService";
import VentaService from "@/service/VentaService";
export default {
  data() {
    return {
      caja: {},
      caja_id: this.$route.params.id,
      claseEstado: ["bg-", "bg-green-100", " bg-red-100"],
      claseEstado2: ["status-outofstock", "status-instock", "status-lowstock"],
    };
  },
  cajaService: null,
  ventaService: null,
  created() {
    this.cajaService = new CajaService();
    this.ventaService = new VentaService();
  },
  mounted() {
    this.cargarCaja();
  },
  methods: {
    acciones(datos) {
      return [
        {
          label: "Ver Venta",
          icon: "pi pi-fw pi-eye",
          /* disabled:
            "Venta Ver Detalle" in this.auth.user.permissions ? false : true, */
          command: () => {
            this.detalleVenta(datos.id);
          },
        },
        {
          label: "Anular Venta",
         /*  disabled:
            "Venta Anular" in this.auth.user.permissions ? false : true, */
          icon: "pi pi-fw pi-trash",
          disabled: datos.estado == 0 ? true : false,
          command: () => this.anularVenta(datos.id),
        },
      ];
    },
    anularVenta(id) {
      this.$confirm.require({
        message: "¿Está seguro que desea anular la venta?",
        header: "Anular Venta",
        icon: "pi pi-exclamation-triangle",
        acceptLabel: "Si, deseo Anular",
        rejectLabel: "No, Cancelar",
        accept: () => {
          this.ventaService.anularVenta(id).then((response) => {
            this.$toast.add({
              severity: "success",
              summary: "Venta Anulada",
              detail: response.mensaje,
              life: 3000,
            });
            this.cargarCaja();
          });
        },
      });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    detalleVenta(id) {
      this.$router.push({ name: "venta_detalle", params: { id: id } });
    },
    cargarCaja() {
      this.cajaService
        .getCaja(this.caja_id)
        .then((response) => {
          this.caja = response.caja;
          console.log(response.caja);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>